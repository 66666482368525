
export const gps = [
  {
    id: "1",
    title: 'Στάθμευση',
    place: 'Παραλία Ιτέα (ΜΑΪΑΜΙ)',
    type:'parking',
    gps: {
      lat: 38.42893,
      lng: 22.42999
    }
  },
  {
    id: "2",
    title: 'Στάθμευση',
    place: 'Παραλία Γαλαξίδι',
    type:'parking',
    gps: {
      lat: 38.38570,
      lng: 22.37595
    }
  },


  {
    id: "3",
    title: 'Ξαπλώστρα',
    place: 'Παραλία Ιτέα (ΜΑΪΑΜΙ)',
    type:'umbrella',
    gps: {
      lat: 38.42893,
      lng: 22.42999
    }
  },
  {
    id: "4",
    title: 'Ξαπλώστρα',
    place: 'Παραλία Γαλαξίδι',
    type:'umbrella',
    gps: {
      lat: 38.38570,
      lng: 22.37595
    }
  },



  {
    id: "5",
    title: 'Χημική τουαλέτα',
    place: 'Παραλία Ιτέα (ΜΑΪΑΜΙ)',
    type:'wc',
    gps: {
      lat: 38.42893,
      lng: 22.42999
    }
  },
  {
    id: "6",
    title: 'Χημική τουαλέτα',
    place: 'Παραλία Γαλαξίδι',
    type:'wc',
    gps: {
      lat: 38.38570,
      lng: 22.37595
    }
  },



  {
    id: "7",
    title: 'Ντουζιέρα',
    place: 'Παραλία Ιτέα (ΜΑΪΑΜΙ)',
    type:'shower',
    gps: {
      lat: 38.42893,
      lng: 22.42999
    }
  },
  {
    id: "8",
    title: 'Ντουζιέρα',
    place: 'Παραλία Γαλαξίδι',
    type:'shower',
    gps: {
      lat: 38.38570,
      lng: 22.37595
    }
  }


];
import React, { useState } from 'react'
import { Icon } from 'leaflet'
import { Row, Col, Drawer, Button } from 'antd'
import { ZoomControl, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { MenuOutlined } from '@ant-design/icons';
import { withTranslation } from "react-i18next"
import { ParkingImage, ShowerImage, UmbrellaImage, WCImage, CloudImage }  from './svg'

import 'leaflet/dist/leaflet.css'
import classes from "./styles.module.css"
import { useWeather } from 'hooks/useWeather'

import marker_parking from "./images/map_marker_parking.svg"
import marker_shower from "./images/map_marker_shower.svg"
import marker_umbrella from "./images/map_marker_umbrella.svg"
import marker_wc from "./images/map_marker_wc.svg"
import { gps } from './gps'

export const parkingIcon = new Icon({
  iconUrl: marker_parking,
  popupAnchor: [0, -20],
  iconSize: [56, 56]
});

export const showerIcon = new Icon({
  iconUrl: marker_shower,
  popupAnchor: [0, -20],
  iconSize: [56, 56]
});

export const umbrellaIcon = new Icon({
  iconUrl: marker_umbrella,
  popupAnchor: [0, -20],
  iconSize: [56, 56]
});

export const wcIcon = new Icon({
  iconUrl: marker_wc,
  popupAnchor: [0, -20],
  iconSize: [56, 56]
});


const Maps: React.FC = ({ t }: any) => {
  
  const { weatherData } = useWeather()
  const [myNewArray, setMyNewArray] = useState(gps);
  const [openWeather, setOpenWeather] = useState<boolean>(false);

  const onClickFocus = (type:string) => {

    let data: any = [];
    data = gps.filter((event) => event.type.includes(type))
    setMyNewArray(data)
  }


  const Markers = () => {

    return <>
      (
      {myNewArray.map((data: any) => {
        return (
          <Marker
            key={data.id}
            position={[data.gps.lat, data.gps.lng]}
            icon={
              data.type === 'parking' ? parkingIcon
                : data.type === 'umbrella' ? umbrellaIcon
                  : data.type === 'shower' ? showerIcon
                    : wcIcon

            }
          >
            <Popup>
              <div className={classes.gpsInfoWindow}>
                <p>{data.title}{" "}</p>
                <p>{data.place}</p>
              </div>
            </Popup>
          </Marker>
        )
      })
      })
    </>
  }
  
  const [open, setOpen] = useState(true);

  const showDrawer = () => { setOpen(prev => !prev) }

  const onCloseWeather = () => { setOpenWeather(false) }

  return (
    <>
      <Row justify="center" style={{paddingTop: '80px'}}>
        <Col xs={24} lg={24} xl={24} >
          <div  className={classes.test}>
            <Button type="primary" icon={<MenuOutlined />} onClick={showDrawer} size={'large'} className={classes.button}>
              Φίλτρα
            </Button>
          </div>
          
          <div id='#wrapper' className={classes.drawerWrapper} >

            <Drawer
              autoFocus={false}
              mask={false}
              placement={'left'}
              closable={false}
              visible={open}
              getContainer={false}
              style={{ position: 'absolute' }}
              bodyStyle={{ width: '130px' }}
              contentWrapperStyle={{ width: '130px' }}
            >
              <p className={classes.p}></p>
              <p className={classes.p}></p>
              <p className={classes.p}></p>
              <p className={classes.p}>
                <Button type="link" onClick={()=>onClickFocus('parking')} >
                  <ParkingImage /> <span style={{display: 'none', color: 'black'}}>none text</span>
                </Button>
              </p>
              <p className={classes.p}>
                <Button type="link" onClick={()=>onClickFocus('shower')} >
                  <ShowerImage /> <span style={{display: 'none', color: 'black'}}>none text</span>
                </Button>
              </p>
              <p className={classes.p}>
                <Button type="link" onClick={()=>onClickFocus('umbrella')} >
                  <UmbrellaImage /> <span style={{display: 'none', color: 'black'}}>none text</span>
                </Button>
              </p>
              <p className={classes.p}>
                <Button type="link" onClick={()=>onClickFocus('wc')} >
                  <WCImage /> <span style={{display: 'none', color: 'black'}}>none text</span>
                </Button>
              </p>
              <p className={classes.p}>
                <Button type="link" onClick={()=>setOpenWeather(prevCheck => !prevCheck)} >
                  <CloudImage /> <span style={{display: 'none', color: 'black'}}>none text</span>
                </Button>
              </p>
            </Drawer>

            <Drawer 
              mask={false}
              zIndex={9999999}
              title={"Μετεωρολογικά Δεδομένα"}
              placement={'right'}
              onClose={onCloseWeather}
              visible={openWeather}
              getContainer={false}
              style={{ position: 'absolute' }}
              bodyStyle={{ width: '300px' }}
              contentWrapperStyle={{ width: '300px' }}
            >
              <span className={classes.weather}>
                <div className={classes.container}>
                  <div>Θερμοκρασία</div>
                  <div className={classes.right}> {weatherData[0]?.degree} &#8451;</div>
                </div>
              </span>
              <span className={classes.weather}>
                <div className={classes.container}>
                  <div>Αίσθηση θερμοκρασίας</div>
                  <div className={classes.right}> {weatherData[0]?.degree_feels} &#8451;</div>
                </div>
              </span>
              <span className={classes.weather}>
                <div className={classes.container}>
                  <div>Υγρασία</div>
                  <div className={classes.right}> {weatherData[0]?.humidity} &#x26C5;</div>
                </div>
              </span>
              <span className={classes.weather}>
                <div className={classes.container}>
                  <div>Κατεύθυνση ανέμου</div>
                  <div className={classes.right}> {weatherData[0]?.wind_deg} </div>
                </div>
              </span>
              <span className={classes.weather}>
                <div className={classes.container}>
                  <div>Ταχύτητα ανέμου</div>
                  <div className={classes.right}> {weatherData[0]?.wind_speed} m/s</div>
                </div>
              </span>
            </Drawer>

            <MapContainer
              center={[38.401148505508645, 22.395591614264276]}
              zoom={13}
              // scrollWheelZoom={false}
              tap={false}
              style={{ height: '600px', width: '100%', zIndex:'0' }}
              zoomControl={false}
            >
              <ZoomControl position='bottomright' />
              <TileLayer
                // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                // url="https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"
              />
              <Markers/>
              </MapContainer>
            </div>
        </Col>
      </Row>
    </>
  )
}

export default withTranslation()(Maps)

import React from 'react'
import { Row, Col, Carousel, Button } from 'antd'
import classes from "./styles.module.css"
import { Icon } from 'leaflet'
import { ZoomControl, MapContainer, TileLayer, Marker } from 'react-leaflet'
import { useWeather } from 'hooks/useWeather'
import { useTranslation } from "react-i18next"
import marker from  "pages/Home/components/map/images/map_marker.svg"

export const GalaxidiIcon = new Icon({
  iconUrl: marker,
  popupAnchor: [0, -20],
  iconSize: [56, 56]
});


const CarouselsButtom:React.FC = () => {

  const { t } = useTranslation()
  const { isLoading, weatherData } = useWeather()


  const showInMapClicked = () => {
    window.open("https://www.google.com/maps/dir//38.382532142397594,+22.380138573766118/@38.3866365,22.2749142,13z/data=!4m6!4m5!1m0!1m3!2m2!1d22.3801386!2d38.3825321")
  };

  if(isLoading) { return  <></> } 
  
  return (

    <Row>
      <Col xs={24} lg={12} xl={12}>
        <div className={classes.container}>
          <Carousel autoplay arrows >

          <div>
              <img className={classes.image} alt="galaxidi" src="./img/galaxidi/1.jpg" />
            </div>
            <div>
              <img className={classes.image} alt="galaxidi 2" src="./img/galaxidi/2.jpg" />
            </div>
            <div>
              <img className={classes.image} alt="galaxidi 3" src="./img/galaxidi/3.jpg" />
            </div>
            <div>
              <img className={classes.image} alt="galaxidi 4" src="./img/galaxidi/4.jpg" />
            </div>

          </Carousel>
        </div>
      </Col>
      <Col xs={24} lg={12} xl={12}>

        <div className={classes.my_wrapper}>
          <div><img className={classes.my_pointer} alt='bla' src="./../img/map_pointer.png" />
            <span className={classes.text} style={{paddingTop: '20px', fontSize: '26px'}}> {t("Delphi")}</span>
          </div>
          <div className={classes.text}>
            {t("Today")} <br />
            <img
              className={classes.my_image}
              alt='bla'
              src={weatherData[0]?.image}
            />{weatherData[0]?.degree} &#8451;
          </div>
        </div>

        <div className={classes.my_wrapper2}>

          <div className={classes.title}>{t("Galaxidi_Beach")} </div>
          <div className={classes.p}>

            Πλήρως οργανωμένη παραλία στο Γαλαξίδι και σε πολύ κοντινή απόσταση. Διαθέτει ομπρέλες, ξαπλώστρες, καθώς και beach bar.<br />

            <strong><h4>Ανέσεις ΑΜΕΑ</h4></strong>
            <strong>Η παραλία διαθέτει:</strong><br />
            <b>1.</b> Κινητή διάταξη πτυσσόμενου διαδρόμου πάνω στον οποίο κινείται τροχήλατο κάθισμα όπου παρέχει αυτόνομη πρόσβαση ατόμων με κινητικά πρόβλήματα στη θάλασσα.
            Ο χρήστης φτάνει με το αμαξίδιο του μέσω διαδρομών πρόσβασης και επιβιβάζεται στο τροχήλατο κάθισμα της διάταξης. Με την χρήση τηλεχειριστηρίου
            ενεργοποιεί τον μηχανισμό κίνησης κινώντας έτσι το κάθισμα προς την θάλασσα. Φτάνοντας στο τέλος της διαδρομής ο χρήστης βρίσκεται σε τέτοιο
            βάθος ώστε μπορεί να επιπλεύσει, να φύγει από το κάθισμα και να κολυμπήσει. Η αντίστροφη διαδικασία ακολουθείται για την έξοδο στην παραλία.<br />
            <b>2.</b> Αμφίβιο αμαξίδιο καλύμβησης που εξυπηρετεί τα ΑμεΑ για την κολύμβηση τους. <br />
            <b>3.</b> Κινητό διάδρομο παραλίας για χρήση ΑμεΑ<br />
            <b>4.</b> Φορητός χώρος υγιεινής προδιαγραφών για ΑμεΑ<br />
            <b>5.</b> Λυόμενο ξύλινο αποδυτήριο προδιαγραφών για ΑμεΑ με ντουζ<br />
            <b>6.</b> Χώρο σκίασης<br /><br />
          </div>
          <div className={classes.center}>
            {/* <Button type="primary" shape="round" size={'large'} className={classes.red}>{t("Photos")} </Button> */}
            <Button type="primary" shape="round" size={'large'} className={classes.red_empty} onClick={showInMapClicked}>{t("Instructions")} </Button>
          </div>

          <div className={classes.wrapper2}>
            <div className={classes.row}>

              <Row>
                <Col xs={12} lg={6} xl={6}>
                  <div className={classes.block} >
                    <img alt="gola" src="./../img/parking.png" />
                    <p> {t("Parking")}</p>
                  </div>
                </Col>
                <Col xs={12} lg={6} xl={6}>
                  <div className={classes.block} >
                    <img alt="gola" src="./../img/umbrella.png" />
                    <p> {t("Deck chair")}</p>
                  </div>
                </Col>


                <Col xs={12} lg={6} xl={6}>
                  <div className={classes.block} >
                    <img alt="gola" src="./../img/WC.png" />
                    <p> {t("Chemical toilet")}</p>
                  </div>
                </Col>
                <Col xs={12} lg={6} xl={6}>
                  <div className={classes.block} >
                    <img alt="gola" src="./../img/shower.png" />
                    <p> {t("Shower")}</p>
                  </div>
                </Col>
              </Row>

            </div>
          </div>

          <MapContainer
              center={[38.384148505508645, 22.380591614264276]}
              zoom={14}
              tap={false}
              style={{ height: '310px', width: '100%', zIndex:'0', borderRadius: '30px' }}
              zoomControl={false}
            >
              <ZoomControl position='bottomright' />
              <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                // url="https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"
              />
             <Marker key='galaxidi' position={[38.38570, 22.37595]} icon={GalaxidiIcon} />
      
          </MapContainer>

        </div>
      </Col>
    </Row>
  
  )
}

export default CarouselsButtom

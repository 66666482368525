import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  padding: 0rem 0.5rem;
  border-bottom: 1px solid rgb(201, 199, 199);
  // margin-bottom:11px;
  .ant-row-space-between {
    align-items: center;
    text-align: center;
    margin-right: 20px;
  }
  height:120px;
  @media only screen and (max-width: 600px) {
    height:108px;
  }
`;

 export const LogoContainer = styled(Link)`
    display: flex;
 `;


export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;
  @media only screen and (max-width: 411px) {
    width: 150px;
  }
  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }
  display: none;
  svg {
    fill: #232224;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menus = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.5rem;
  color: #18216d;
  transition: color 0.2s ease-in;
  margin: 0.5rem 2rem;

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const CustomNavLinkSmallImage = styled(NavLink)`
  font-size: 1.4rem;
  color: #18216d;
  transition: color 0.2s ease-in;
  margin: 0.5rem 2rem;
  vertical-align: middle;
  margin-left: 86px;
  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
    margin-left: 0px;
  }
`;



export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;

export const Span = styled("span")`
  cursor: pointer;
  margin-top:20px;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    color: rgb(44, 51, 120);
    text-underline-position: under;
    text-decoration: rgb(44, 51, 120) underline;
  }
`;
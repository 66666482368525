import React from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { withTranslation } from "react-i18next"


const Texts : React.FC = ({ t }: any) => {

  return (

    <Row className={classes.paddingTop}>

      <Col xs={24} lg={12} xl={12}>
        {/* <div className={classes.container}>
          <h2>Life is beautiful</h2>
          <ul>
            <li>Coffee</li>
            <li>Tea</li>
            <li>Coca Cola</li>
          </ul>
          <p>Dotsoft power</p>
        </div> */}
      </Col>

      <Col xs={24} lg={24} xl={12}>
        <div className={classes.container}>
          <img className={classes.responsive} alt='bla' src="./../img/woman.png" />
        </div>
      </Col>

    </Row>
  
  );
};


export default withTranslation()(Texts)

import React from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { withTranslation } from "react-i18next"
import {useNavigate} from 'react-router-dom'

const Beaches: React.FC = ({ t }: any) => {

  const navigate = useNavigate();

  const navigateGalaxidi = () => {
    navigate('/beachGalaxidi');
  };
  const navigateMaiami = () => {
    navigate('/beachMaiami');
  };


  return (

    <Row>
      <Col xs={24} lg={12} xl={12}>
        <div className={classes.container} onClick={navigateGalaxidi}>
          <img className={classes.image} alt='Paralia Galaxidy' src="./../img/paralia_galaksidi.jpg" />
          <div className={classes.box}>
            <div className={classes.wrapper}>
              <img className={classes.pointer} alt='Map pointer 1' src="./../img/map_pointer.png" />
              <div className={classes.h4}>{t("Galaxidi_Beach")}</div>
            </div>


            <div className={classes.wrapper2}>  
              <div className={classes.h5}>{t("Allowances")}</div>
              <div className={classes.row}>
                <img alt='Image parking' src="./../img/parking.png" />  
                <img alt='Image umbrella' src="./../img/umbrella.png" />     
                <img alt='Image wc' src="./../img/WC.png" />
                <img alt='Image shower' src="./../img/shower.png" />     
              </div>
            </div>


            </div>
        </div>
      </Col>
      <Col xs={24} lg={12} xl={12}>
        <div className={classes.container} onClick={navigateMaiami}>
          <img className={classes.image} alt='Paralia Mayami' src="./../img/paralia_maiami.jpg" />
          <div className={classes.box}>
            <div className={classes.wrapper}>
              <img className={classes.pointer} alt='Map pointer 2' src="./../img/map_pointer.png" />
              <div className={classes.h4}>{t("Miami_Beach")}</div>
            </div>

            <div className={classes.wrapper2}>  
              <div className={classes.h5}>{t("Allowances")}</div>
              <div className={classes.row}>
                <img alt='Image parking' src="./../img/parking.png" />  
                <img alt='Image umbrella' src="./../img/umbrella.png" />     
                <img alt='Image wc' src="./../img/WC.png" />
                <img alt='Image shower' src="./../img/shower.png" />     
              </div>
            </div>
            </div>
        </div>
      </Col>
    </Row>
  
  );
};

export default withTranslation()(Beaches)

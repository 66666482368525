import React, { lazy } from "react"
import Carousels from 'pages/BeachGalaxidi/components/carousel'
import CarouselsButtom from 'pages/BeachGalaxidi/components/carouselButtom'

const Container = lazy(() => import("common/Container"))

const BeachGalaxidi =  () => {

  return (
    <Container>
      <Carousels />
      <CarouselsButtom />
    </Container> 
  )
}

export default BeachGalaxidi

import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`
    @font-face {
        font-family: "SFProText";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }
    @font-face {
        font-family: "SFProText";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }
    body,
    html,
    a {
        font-family: 'SFProText', sans-serif;
    }
    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }
    a:hover {
        color: #navy;
    }
    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;
        :focus-within {
            background: none;
            box-shadow: #232224 0px 0px 0px 1px;
        }
    }
    h1,
    h2
     {
        // font-family: 'SFProText', serif;
        color: #2c2b2b;
        font-size: 56px;
        line-height: 1.18;
        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }
    h3
     {
        font-weight:bold;
        color: #2c2b2b;
        font-size: 38px;
        line-height: 1.18;
        @media only screen and (max-width: 890px) {
          font-size: 38px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 30px;
        }
    }
    h4
    {
       font-weight:bold;
       color: #2c2b2b;
       font-size: 28px;
       line-height: 1.18;
       padding: 16px 0px 0px 1px;
       margin-bottom: 26px;
       @media only screen and (max-width: 890px) {
         font-size: 26px;
         padding: 20px;
       }
     
       @media only screen and (max-width: 414px) {
         padding: 10px;
         font-size: 24px;
       }
    }

    h5
    {
       font-weight:bold;
       color: #2c2b2b;
       font-size: 18px;
       line-height: 1.18;
       padding: 10px;
       margin-bottom: 2px;
       @media only screen and (max-width: 790px) {
         font-size: 20px;
         padding: 10px;
       }
     
       @media only screen and (max-width: 414px) {
         padding: 10px;
         font-size: 18px;
         text-align:center;
       }
    }


    p {
        color: #18216d;
        font-size: 17px;        
        line-height: 1.41;
    }
    h1 {
        font-weight: 600;
    }
    a {
        text-decoration: none;
        outline: none;
        color: #232224;
        :hover {
            color: #232224;
        }
    }
    
    *:focus {
        outline: none;
    }
    .about-block-image svg {
        text-align: center;
    }


    .ant-input-group-addon:first-child {
        border-radius: 10px 0px 0px 10px;
        background-color: #01ae74;
        color: white;
        font-weight: 800;
        border-color: #01ae74;
    }
    .ant-input-group-addon:last-child, .ant-input-group-addon:last-child .ant-select .ant-select-selector, .ant-input-group>.ant-input:last-child, .ant-input-group>.ant-input:last-child .ant-select .ant-select-selector {
        border-radius: 0px 10px 10px 0px;
        background-color: white;
        border-color: #01ae74;
    } 
    .ant-rate {
        color: #dfc312;
    }

    .ant-btn-link {
        padding: 0px;
        margin-left:4px;
    }




    .ant-select-single .ant-select-selector .ant-select-selection-item {
        margin-left: 26px;
        margin-top: 2px;
    }

    .ant-select-selection-placeholder{
        margin-left: 26px;
        margin-top: 2px;
        color: #009664;
    }


    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius:6px;
        font-size:16px;
    }




    .ant-option  {
        margin-left: 4px; 
        background-color: #fff !important;
        @media only screen and (max-width: 600px) {
            margin-left: 0px; 
        }
    }

    .type .ant-select-selector  {
        background: url(../../../img/events/type.svg) no-repeat;
        background-position: 8px;  
        width:34%; 
        @media only screen and (max-width: 600px) {
            width:100% !important;
        }
    }

    .calendar .ant-select-selector  {
        background: url(../../../img/events/calendar.svg) no-repeat;
        background-position: 8px;   
    }
    .municipality .ant-select-selector  {
        background: url(../../../img/events/municipality.svg) no-repeat;
        background-position: 8px;   
    }


    img {
        vertical-align: middle;
        border-style: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .img_home {
        border-radius: 8px;
        width:100%;
        position: relative;
    }


    svg:hover { fill: #01AE74;}




    ///////////////////////////////////////////    MAP    /////////////////////////////////////////////////
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #01AE74;
        border-color: #01AE74;
      }
    .ant-card-head {
        border-bottom: 0px;
        min-height: 4px;
        height:26px;
        padding: 0 10px;
    }
    .ant-card-body{
        max-height: 120px;
        padding:6px;
    }
    .ant-card-extra {
        margin-left: auto;
        padding: 6px 0px;
    }
    
    .ant-rate-star:not(:last-child) {
        margin-right: 6px;
    }


    .ant-checkbox-group-item {
        display: flex;
        padding:6px;
        margin-right: 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #073e23;
    }

    .ant-tabs-tab:hover{
        color: black;
    }

    .ant-tabs-ink-bar {
        position: absolute;
        background: #0cbb65;
        pointer-events: none;
    }

    .ant-image {
        padding: 3px;
    }

    .no-mask{
        width: 8% !important;
    }

    @media only screen and (max-width: 790px) {
        .ant-btn-lg {
            height: 40px;
            padding: 6.4px 7px;
        }
        .ant-rate-star:not(:last-child) {
            margin-right: 6px;
        }

        .no-mask{
            width: 30% !important;
        }

    }

   

    // .clicked-fill {
    //     fill: #A8B2BD;
    // }

    // .fill{
    //     fill: #01AE74;
    // }

    #svg_calendar:hover { stroke: #01AE74; }





    .active-link{
        color: red;
        cursor:default;
    }


    .slick-arrow.slick-prev {
        font-size: 10px;
        
      }
      .ant-carousel .slick-prev::before {
        content: '<';
        display: block;
        position: relative;
        bottom: 20px;
        right: -36px;
        font-size: 25px;
        color: gray;
        background-color: white;
        border: 2px solid gray;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 5px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        z-index: 1;
         
      }
      .slick-arrow.slick-next {
        font-size: 10px;
      }
      .ant-carousel .slick-next::before {
        content: '>';
        display: block;
        position: relative;
        right: 60px;
        bottom: 20px;
        /* width: 100px; */
        font-size: 25px;
        color: gray;
        background-color: white;
        border: 2px solid gray;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        z-index: 1;
      }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
        line-height:1;
        margin-right:20px;
    }

    

     






`;
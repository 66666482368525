// import Carousel from 'better-react-carousel'
import React from 'react'
import classes from "./styles.module.css"
import { Carousel } from 'antd'
import { withTranslation } from "react-i18next"


const Carousels : React.FC = ({ t }: any) => {

  return (

    <Carousel autoplay arrows >
      <div>
          <div className={classes.container}>
          
            <img alt="image slider 1" className={classes.image} src="./../img/slider_1.jpg" />
            <div className={classes.box}>
              <div>
                <div className={classes.h4Carousel}><h1>{t("Delfon")} |</h1></div>
                <p className={classes.pCarousel}>«{t("Delfon_p")}»</p>
                <img alt="4 small images" className={classes.image4} src="./../img/slider_1_4_images.png" />
              </div>
            </div>
        </div>
        
      </div>
      {/* <div>
        <h3 style={contentStyle}>2</h3>
      </div>
      <div>
        <h3 style={contentStyle}>3</h3>
      </div>
      <div>
        <h3 style={contentStyle}>4</h3>
      </div> */}
    </Carousel>
  
  );
};


export default withTranslation()(Carousels)

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Styles } from "styles/styles"
import { I18nextProvider } from "react-i18next"
import i18n from "translation";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'


import Header from "layoutComponents/Header"
import Footer from "layoutComponents/Footer"

import Home from "pages/Home"
import BeachGalaxidi from "pages/BeachGalaxidi"
import BeachMaiami from "pages/BeachMaiami"
import Common from "pages/Common"
import PageNotFound from "pages/404"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
})


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={null}>
          <Styles />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/beachGalaxidi" element={<BeachGalaxidi />} />
            <Route path="/beachMaiami" element={<BeachMaiami />} />
            <Route path="/:page" element={<Common />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </Suspense>
      </I18nextProvider>
    </BrowserRouter>
  </QueryClientProvider>
)

import React, { useState } from "react"
import type { MenuProps } from 'antd'
import { Row, Col, Drawer, Dropdown, Space, Menu } from 'antd'
import { GlobalOutlined } from '@ant-design/icons';
import 'antd/dist/antd.min.css'
import { withTranslation } from "react-i18next"
import i18n from "i18next"
import Container from "common/Container"
import { Icon } from "common/Icon"
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menus,
  CustomNavLinkSmall,
  CustomNavLinkSmallImage,
  Label,
  Outline,
  Span,
} from "./styles"


const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false)
  const [height, setHeight] = useState('110px')


  const showDrawer = () => {
    setVisibility(!visible)
    setHeight('60px')
  };

  const onClose = () => {
    setVisibility(!visible)
    setHeight('110px')
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    i18n.changeLanguage(key)
  };

  const setActive = ({ isActive }: any) => isActive ? 'active-link' : ''

  const MenuItem: React.FC = () => (
      <>
        <CustomNavLinkSmall>
        {/* <Span>
          <NavLink to="/beach" className={setActive} >{t("Beach")}</NavLink>
        </Span> */}
          <Span>
            <HashLink smooth to="/#beaches">{t("Beach")}</HashLink>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Span>
            <HashLink smooth to="/#goals">{t("Goals")}</HashLink>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Span>
            <HashLink smooth to="/#map">{t("Map")}</HashLink>
          </Span>
        </CustomNavLinkSmall>
       
        <CustomNavLinkSmall>
          <Span>
            <HashLink smooth to="/#mobile">{t("Mobile App")}</HashLink>
          </Span>
        </CustomNavLinkSmall>

        <CustomNavLinkSmallImage >
          <Span>
          <LogoContainer to="/" aria-label="homepage" >
            <Icon src="espa.jpg" alt="Logotip Espa" width="400px" height={height} />
          </LogoContainer>
          </Span>
        </CustomNavLinkSmallImage>    
      </>
    )
  

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between" id="home">
          <LogoContainer to="/" aria-label="homepage" >
            <Icon src="logo.svg" alt='Logotip' width="140px" height="100px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menus>Menu</Menus>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  )
}

export default withTranslation()(Header)
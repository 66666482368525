import React from 'react'
import classes from "./styles.module.css"
import { Carousel } from 'antd'
import { withTranslation } from "react-i18next"

const Carousels : React.FC = ({ t }: any) => {

  return (
    <Carousel autoplay arrows >
      <div>

        <div className={classes.container}>
          <img className={classes.image} src="./img/beaches/Galaxidi_Slider.jpg" alt="Paralia Galaxidi"/>
          <div className={classes.box}>
            <div className={classes.box2}>
              <div className={classes.wrapper}>
                <img className={classes.pointer} alt='Galaxidi Slider Header' src="./../img/map_pointer.png" />
                <div className={classes.h4}>{t("Galaxidi_Beach")}</div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* <div>
        <h3 style={contentStyle}>2</h3>
      </div>
      <div>
        <h3 style={contentStyle}>3</h3>
      </div>
      <div>
        <h3 style={contentStyle}>4</h3>
      </div> */}
    </Carousel>
  
  );
};


export default withTranslation()(Carousels)

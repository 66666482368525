import axios from 'axios'

export const axiosWeather = {

  async getWeather() {

    const { data } = await axios.get('https://api.openweathermap.org/data/2.5/weather?lat=38.401148505508645&lon=22.395591614264276&appid=8023e4182205203115c3cfe9fbcc1edf&units=metric&lang=el')

    return data
  }
}

import React, { lazy } from "react"
import Carousels from 'pages/BeachMaiami/components/carousel'
import CarouselsButtom from 'pages/BeachMaiami/components/carouselButtom'

const Container = lazy(() => import("common/Container"))

const BeachMaiami =  () => {

  return (
    <Container>
      <Carousels />
      <CarouselsButtom />
    </Container> 
  )
}

export default BeachMaiami

import React from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { withTranslation } from "react-i18next"


const Goals : React.FC = ({ t }: any) => {

  return (

  <>
    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Col>
        <div className={classes.goals}>{t("Goals")}</div>
      </Col>
    </Row>
    <Row justify="center">
      <Col xs={24} lg={8} xl={8} span={3}>
        <div className={classes.containerRight}>
          <div className={classes.block} >
            <img alt="Ισότιμη Πρόσβαση image" src="./../img/goals_1.png"/>
            <h3> { t("Equal_Access") }</h3>
          </div>
        </div>
      </Col>
      <Col xs={24} lg={8} xl={8} span={3}>
        <div className={classes.containerCenter}>
          <div className={classes.block} >
            <img alt="Προσέλκυση τουριστών ΑΜΕΑ image" src="./../img/goals_2.png"/>
            <h3>{ t("Attracting_disabled_tourists") }</h3>
          </div>
        </div>
      </Col>
      <Col xs={24} lg={8} xl={8} span={3}>
        <div className={classes.containerLeft}>
          <div className={classes.block} >
            <img alt="Τουριστικές υπηρεσίες για ΑΜΕΑ image" src="./../img/goals_3.png"/>
            <h3> {t("Tourist_services_for_the_disabled")}</h3>
          </div>
        </div>
      </Col>
    </Row>
    </>

  );
};


export default withTranslation()(Goals)

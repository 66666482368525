import React, { lazy } from "react"

import Carousels from 'pages/Home/components/carousel'
import Beaches from 'pages/Home/components/beaches'
import Goals from 'pages/Home/components/goals'
import Maps from 'pages/Home/components/map'
import Texts from 'pages/Home/components/text'

const Container = lazy(() => import("common/Container"))

const Home = () => {

  return (
    <>
      <Container>
        <Carousels />
        <br />
        <a id="beaches" href="#beaches"><span style={{display: 'none'}}>none text</span> </a>
        <Beaches />
        <a id="goals" href="#goals"><span style={{display: 'none'}}>none text</span> </a>
        <Goals />
        <a id="map" href="#map"><span style={{display: 'none'}}>none text</span> </a>
        <Maps />
        <a id="mobile" href="#mobile"><span style={{ display: 'none' }}>none text</span> </a>
        <Texts />
      </Container>
    </>
  )
}

export default Home
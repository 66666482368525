import React, { lazy } from "react"
import { Link } from 'react-router-dom'
// import { APP_NAME } from "configs/AppConfig"
import { Row, Col } from "antd"
import 'antd/dist/antd.min.css'
import { withTranslation } from "react-i18next"
import { Icon } from "common/Icon"
import Container from "common/Container"
import i18n from "i18next"
import classes from "./styles.module.css"
import {
  FooterSection,
  NavLink,
  LogoContainer
} from "./styles"
import { HashLink } from 'react-router-hash-link';



const ScrollToTop = lazy(() => import("common/ScrollToTop"))

const Footer = ({ t }: any) => {

  const onClickHref = (href:string) => {
     window.location.href = `../#${href}`
  }


  return (
    <>
      <FooterSection>
        <Container>
          <ScrollToTop />

          <Row className={classes.divBackground} justify="space-between">
            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6}} lg={{ span: 12, offset: 6}}>
                <Row className={classes.divBackground}>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <HashLink smooth to="/#beaches"><span className={classes.footerMenu}>{t("Beach")}</span></HashLink>  
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <HashLink smooth to="/#goals"><span className={classes.footerMenu}>{t("Goals")}</span></HashLink>  
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <HashLink smooth to="/#map"><span className={classes.footerMenu}>{t("Map")}</span></HashLink>  
                  </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <HashLink smooth to="/#mobile"><span className={classes.footerMenu}>{t("Mobile App")}</span></HashLink>  
                  </Col>
                </Row>
            </Col>
          </Row>
      
          <Row className={classes.divBackgroundCenter}>
            <Col span={12} offset={6}>
              <div className={classes.divBorder}></div>
            </Col>
          </Row>

          <Row className={classes.divBackground}>
            <Col xs={{ span: 23, offset: 1 }} sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6}} lg={{ span: 12, offset: 6}}>
                <Row className={classes.divBackground}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.footerMenu_}><NavLink to="/terms-and-conditions" state={{ page: 'terms-and-conditions' }}> {t("Terms and conditions")}</NavLink></div>   
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.footerMenu_}><NavLink to="/privacy" state={{ page: 'privacy' }}> {t("Privacy")}</NavLink></div> 
                  </Col>
     
                </Row>
            </Col>
          </Row>

          <Row> 
            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6}} lg={{ span: 16, offset: 4}}>
                <Row>
                  <Col lg={6} md={10} sm={12} xs={24}>
                    <NavLink to="/">
                      <LogoContainer>
                        <Icon
                          src="logo.svg"
                          aria-label="homepage"
                          width="180px"
                          height="160px"
                          alt='My home page logo'
                        />
                      </LogoContainer>
                    </NavLink>
                    {/* <img alt="" src="./../img/footer_group.png" style={{width:'30%', height: 'auto', marginLeft: '10px'}} /> */}
                  </Col>

                  <Col lg={8} md={4} sm={12} xs={24}>
                    <div className={classes.blocks} >
                      <p>Τηλέφωνο:<span><a href="tel:2265350027"> 2265350027</a></span></p>
                      <p>Email:<span><a href="mailto:mayor@delphi.gov.gr"> mayor@delphi.gov.gr</a></span></p>
                      <p>Facebook:<span><a href="https://www.facebook.com/dimosdelfon"> https://www.facebook.com/dimosdelfon</a></span></p>
                    
                    </div>  
                  </Col>

                  <Col lg={10} md={10} sm={12} xs={24}>
                    <div className={classes.block} >
                      <img alt="image Espa" src="./../img/espa.jpg" />
                    </div>
                  </Col>
                </Row>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer)
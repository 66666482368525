import React, {useEffect} from "react"
import { useLocation } from "react-router-dom"
import classes from './styles.module.css'


import {Terms, PrivacyPolicy, Privacy} from "../../common/Terms_Privacy_Policy"



const Common = () => {

  const location = useLocation()

  let title = ''
  let body = ''

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  switch (location.pathname) {
    case '/terms-and-conditions':
      title = Terms.Title;
      body = Terms.Body
      break;
    case '/privacy-policy':
      title = PrivacyPolicy.Title;
      body = PrivacyPolicy.Body
      break;
    case '/privacy':
      title = Privacy.Title;
      body = Privacy.Body
      break; 
  }


  return (
    <>
      <div className={classes.container}>
        <h3 className={classes.h3}>{ title }</h3>
        <div dangerouslySetInnerHTML={{ __html: body || '' }} />
      </div>
    </>
  )
}

export default Common